@import 'bootstrap/scss/bootstrap';

.wrapper {
  box-shadow: 0px 0.8px 1.2px rgba(0, 0, 0, 0.026),
    0px 1.8px 2.7px rgba(0, 0, 0, 0.038), 0px 3.2px 4.7px rgba(0, 0, 0, 0.047),
    0px 5px 7.5px rgba(0, 0, 0, 0.055), 0px 7.7px 11.6px rgba(0, 0, 0, 0.063),
    0px 12.1px 18.1px rgba(0, 0, 0, 0.072), 0px 20px 30px rgba(0, 0, 0, 0.084),
    0px 40px 60px rgba(0, 0, 0, 0.11);
  border-radius: 10px;
  max-width: 900px;
  margin: auto;
}

.header {
  position: relative;
  background-color: #ececeb;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #cfcfce;
  padding: 4px 8px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.circles {
  position: absolute;
  display: flex;
  gap: 5px;
}

.circles > .circle > span {
  visibility: hidden;
}

.circles:hover > .circle > span {
  visibility: visible;
}

.title {
  margin: auto;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.icon {
  color: rgb(108, 117, 125);
}

.circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.65rem;
  font-weight: bold;
  color: rgb(70, 70, 70);
  cursor: default;
}

.circle-red {
  background-color: #fe5f57;
}

.circle-yellow {
  background-color: #febc2e;
}

.circle-green {
  background-color: #27c840;
}

.ribbon {
  background-color: #ececeb;
  display: flex;
  gap: 20px;
  border-bottom: 1px solid #cfcfce;
  padding: 4px 16px;
  color: #8b8b8b;
}

.pill {
  border-radius: 6px;
  border: none;
  background: none;
  padding: 2px 40px;
  font-size: 0.8rem;
  color: #8b8b8b;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pill.active {
  color: white;
  background-color: #04a54f;
}

.content {
  background: white;
  padding: 40px 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.grid {
  text-align: left;
  font-size: 0.8rem;
  padding: 1px;
  display: grid;
  grid-gap: 1px;
  background: #b7b7b7;
  grid-auto-flow: dense;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: repeat(13, 25px);
}

@include media-breakpoint-up(sm) {
  .grid {
    grid-template-columns: 2fr 3fr 1fr 1fr 1fr;
  }
}

@include media-breakpoint-up(md) {
  .content {
    padding: 50px;
  }
}

.grid-lod {
  grid-template-columns: 1fr 3fr 2fr 4fr;
  grid-template-rows: repeat(13, 25px);
}

.cell {
  background: white;
  padding: 2px 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.qr-code {
  grid-column: 3 / span 2;
  grid-row: 2 / span 7;
  display: flex;
  align-items: center;
  justify-content: center;
}
