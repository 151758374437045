// https://www.joshwcomeau.com/gradient-generator/
.background {
  background-image: linear-gradient(
    315deg,
    hsl(305deg 100% 35%) 0%,
    hsl(313deg 69% 45%) 10%,
    hsl(319deg 61% 52%) 20%,
    hsl(325deg 66% 58%) 30%,
    hsl(331deg 70% 64%) 40%,
    hsl(337deg 75% 69%) 50%,
    hsl(342deg 80% 74%) 60%,
    hsl(347deg 85% 78%) 70%,
    hsl(351deg 90% 82%) 80%,
    hsl(356deg 95% 86%) 90%,
    hsl(0deg 100% 90%) 100%
  );
}
