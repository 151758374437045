// https://www.joshwcomeau.com/gradient-generator/
.background {
  background-image: linear-gradient(
    45deg,
    hsl(240deg 100% 50%) 0%,
    hsl(262deg 79% 56%) 14%,
    hsl(276deg 56% 58%) 29%,
    hsl(306deg 34% 61%) 43%,
    hsl(359deg 40% 72%) 57%,
    hsl(36deg 67% 70%) 71%,
    hsl(51deg 87% 65%) 86%,
    hsl(60deg 100% 50%) 100%
  );
}
