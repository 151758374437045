// Override default variables before the import
// $body-bg: #000;

// Import Bootstrap and its default variables
@import 'bootstrap/scss/bootstrap';
@import 'Licenses/Licenses';

.sbomx-app {
  display: grid;
  grid-template-areas:
    'header'
    'main'
    'footer';
  grid-template-rows: auto 1fr auto;
  height: 100vh;
}

@include media-breakpoint-up(md) {
  .sbomx-app {
    grid-template-areas:
      'header header'
      'nav main'
      'nav footer';
    grid-template-columns: 250px;
  }
}

.sbomx-header {
  grid-area: header;
}

.sbomx-main {
  grid-area: main;
  // background-color: #f3f2f1;
  // make sure responsive design works
  // and lists in cards properly truncate text
  min-width: 0;
}

// use for breadcrumb background
.sbomx-background {
  background: rgba(0, 0, 0, 0.03);
}

// use for breadcrumb or tabs as border bottom
.sbomx-border {
  box-shadow: rgb(222 226 230) 0px -1px 0px inset;
}

.sbomx-footer {
  grid-area: footer;
  color: #fff;
}

.button-icon {
  border: none;
  background: none;
}

.dropzone {
  border: 1px solid $gray-300;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
}

.dropzone:hover {
  border: 1px solid $gray-600;
}

.dropzone.active {
  border: 1px solid $gray-600;
  color: $gray-600;
  background-color: $gray-100;
}

.dropzone.file {
  border: 1px solid $gray-600;
}

.offcanvas-sbomx {
  grid-area: nav;
  // same as bootstrap header height
  top: 56px;
  width: 250px;
  border: none;
}

@include media-breakpoint-up(md) {
  .offcanvas-sbomx {
    // reset absolute positioning
    position: static;
    transform: none;
    visibility: visible !important;
  }
}

// since tabs might scroll add some snapping
.nav-tabs-scroll-snap {
  scroll-snap-type: x mandatory;
}

.nav-tabs-scroll-snap > .nav-item {
  scroll-snap-align: center;
}

// .sticky {
//   box-shadow: 0 8px 8px -8px #9d9d9d;
// }
