@import 'bootstrap/scss/bootstrap';

.base {
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  grid-template-rows: repeat(8, min-content);
}

.temporal {
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  grid-template-rows: repeat(3, min-content);
}

.environmetal {
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  grid-template-rows: repeat(11, min-content);
}

@include media-breakpoint-up(lg) {
  .base {
    grid-template-rows: repeat(4, 1fr);
  }

  .environmetal {
    grid-template-rows: repeat(6, 1fr);
  }
}

@include media-breakpoint-up(xl) {
  .temporal {
    grid-template-rows: repeat(2, 1fr);
  }
}

@include media-breakpoint-up(xxl) {
  .environmetal {
    grid-template-rows: repeat(4, 1fr);
  }
}
