@import 'bootstrap/scss/bootstrap';

.version {
  flex: 1;
}

@include media-breakpoint-up(md) {
  .version {
    flex: initial;
    flex-basis: 150px;
  }
}
