@import 'bootstrap/scss/bootstrap';

.background {
  background-image: radial-gradient(#dddddd 1px, transparent 0);
  background-size: 20px 20px;
  padding: 60px 0;
}

@include media-breakpoint-up(md) {
  .background {
    padding: 60px;
  }
}

.wrapper {
  position: relative;
  margin: auto;
  max-width: 900px;
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: repeat(7, 40px);
}

.animation {
  stroke-dasharray: 10px;
  stroke-dashoffset: 20px;
  animation: stroke 0.5s linear infinite;
}

.lila {
  stroke: rgba(119, 54, 231, 0.5);
}

.blue {
  stroke: rgb(0, 0, 255, 0.5);
}

.yellow {
  stroke: rgb(243, 220, 88, 0.5);
}

@keyframes stroke {
  to {
    stroke-dashoffset: 0;
  }
}

.svg {
  position: absolute;
  width: 100%;
  height: 100%;
}

.box {
  position: relative;
  padding: 10px;
  display: flex;
  align-items: center;
  border: 1px solid lightgray;
  border-radius: 6px;
  background: white;
}

.small-wrapper {
  position: relative;
  width: 270px;
  height: 450px;
  margin: auto;
  display: flex;
}

.small-box {
  position: absolute;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid lightgray;
  border-radius: 6px;
  background: white;
  width: 220px;
  height: 60px;
}

.small-sbomx {
  margin: auto;
  border-radius: 6px;
  font-weight: bold;
  padding: 20px 40px;
  border: 3px solid lightgray;
  background: white;
  position: relative;
}

.left {
  justify-content: flex-start;
}

.left::before {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  background: lightgray;
  border-radius: 50%;
  border: 2px solid white;
  position: absolute;
  left: -5px;
}

.right {
  justify-content: flex-end;
}

.right::after {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  background: lightgray;
  border-radius: 50%;
  border: 2px solid white;
  position: absolute;
  right: -5px;
}

.bottom::before {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  background: lightgray;
  border-radius: 50%;
  border: 2px solid white;
  position: absolute;
  top: -5px;
}

.pom-xml {
  grid-column: 1;
  grid-row: 1;
}

// .dot {
//   width: 8px;
//   height: 8px;
//   background: black;
//   border: 1px solid white;
//   border-radius: 50%;
//   position: absolute;
//   right: 0;
//   top: 50%;
// }

.yarn-lock {
  grid-column: 1;
  grid-row: 2;
}

.package-lock-json {
  grid-column: 1;
  grid-row: 3;
}

.dockerfile {
  grid-column: 1;
  grid-row: 4;
}

.build-gradle {
  grid-column: 1;
  grid-row: 5;
}

.go-mod {
  grid-column: 1;
  grid-row: 6;
}

.sbomx {
  grid-column: 3;
  grid-row: 3 / span 2;
  justify-content: center;
  border-radius: 6px;
  border-width: 3px;
  font-weight: bold;
  flex-direction: column;
  text-align: center;
}

.small {
  font-size: 0.6rem;
  font-weight: normal;
}

.csv {
  grid-column: 5;
  grid-row: 1;
}

.excel {
  grid-column: 5;
  grid-row: 2;
}

.cyclone-dx {
  grid-column: 5;
  grid-row: 3;
}

.spdx {
  grid-column: 5;
  grid-row: 4;
}

.swid {
  grid-column: 5;
  grid-row: 5;
}

.package-manager {
  display: flex;
  justify-content: center;
  grid-column: 2;
  grid-row: 7;
}

.vulnerabilities {
  display: flex;
  justify-content: center;
  grid-column: 3;
  grid-row: 7;
}

.advisories {
  display: flex;
  justify-content: center;
  grid-column: 4;
  grid-row: 7;
}
