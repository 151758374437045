// .grid {
//   // without the min() function the columns would stay at 480px which does not work on mobile
//   grid-template-columns: repeat(auto-fit, minmax(min(100%, 480px), 1fr));
//   // same as .mb-4
//   gap: 1.5rem;
// }

@import 'bootstrap/scss/bootstrap';

.grid {
  grid-auto-flow: dense;
  grid-template-columns: 1fr;
  grid-auto-rows: 228px;
  gap: 1.5rem;
}

.span-2 {
  grid-column: 1 / -1;
}

.grid-row-2 {
  grid-row: span 2;
}

@include media-breakpoint-up(lg) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .span-2 {
    grid-column: span 2;
  }
}
