.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-radius: 0.75rem;
  background-color: rgb(248, 249, 250);
  flex-shrink: 0;
}

.text {
  margin-left: 3.5rem;
}
